@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


body { 
    font-family: 'Pretendard', sans-serif !important;
}


.member-profile-uploader > .ant-upload {
    width: 240px;
    height: 240px;
    background: #fff;
    overflow: hidden;
}

.single-page-image-uploader > .ant-upload {
    width: 180px;
    height: 180px;
    background: #fff !important;
    overflow: hidden;
}

.home-image-slider-uploader > .ant-upload {
    width: 800px;
    height: 300px;
    background: #fff !important;
    overflow: hidden;
}

.clickable {
    cursor: pointer;
}
.single-page-container {
    font-size: 14px;
    width: 900px;
    word-wrap: break-word;
    word-break: break-word;
}

/* 이미지가 컨테이너 밖으로 나오는 문제 방지 : */
.single-page-container img{
    max-width: 900px !important;
    height: auto;
}

  

.ant-table-thead {
    white-space: nowrap;
}


.menu-disable-bottom-border.ant-menu-item::after {
    border-width: 0px !important;
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    border-width: 0px;
}




/* TAB Font SIZE */
.ant-tabs-tab-btn {
    font-size: 16px;
}


/* REMOVE UNNECESSARY FONTS from the TinyMCE */
.tox-menu-nav__js[title~=Webdings] {
    display: none !important;
}
.tox-menu-nav__js[title~=Wingdings] {
    display: none !important;
}



.carousel .slide img {
    pointer-events: all;
}
